//@import "~bootstrap/scss/bootstrap";

*:focus {
	outline: none !important;
	box-shadow: none !important;
}

// hide checklist nav item container when sidebar is minimized.
.g-sidenav-hidden {
	.checklist-nav-item-container {
		display: none !important;
	}
}

body {
	background-color: #fff;
}

@media (min-width: 1400px) {
	.container {
		width: 1200px;
	}
}

.parent {
	overflow-y: auto;
	height: 100vh;
	background-color: #fff;

	.mobile-info {
		display: none;
	}

	@media screen and (max-width:920px) {
		overflow: hidden;

		.pl-sidebar {
			display: none !important;
		}

		.nav-section {
			//display: none;
		}

		.mobile-info {
			display: grid;
			justify-content: center;
			min-height: 100vh;
			width: 100%;
			padding: 20px;

			.main-mbl-content {
				padding: 20px;
				padding-top: 50px;
				box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
			}

		}
	}
}

.App {
	text-align: center;
	position: relative;
	min-height: calc(100vh - 55px);
	overflow-y: hidden;

	.checklist-modal {
		position: absolute;
		z-index: 1000;
		left: 10px;
		box-shadow: 0px 4px 8px -2px #1018281A;
		bottom: 80px;
		width: 280px;
		height: 250px;
		background-color: #fff;
		border: 1px solid #E2E4E9;
		border-radius: 12px;
		padding: 20px;

		.checklist-title {
			color: #0A0D14;
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			margin-bottom: 22px;
		}

		.item-completed {
			text-decoration: line-through;
		}

		.form-checklist-input {
			&.form-check-input:checked {
				background-color: #8E68B7;
				border-color: #8E68B7;
			}
		}
	}
}

select {
	background-color: #fff;
}

h3 {
	font-size: 1.0625rem;
}



.btn-primary {
	background-color: #5e72e4;
	border-color: #5e72e4;
	color: #fff;

	&:hover {
		background-color: #5e72e4;
		border-color: #5e72e4;
		color: #fff;
	}
}

// arrow dropdown.
.pl-arrow-dropdown .btn {
	background-color: transparent;
	color: #212529;
	border: none;
	box-shadow: none;
	padding: 0;
	margin: 0;
	font-weight: 300;
	font-size: 14px;
}

.row {
	margin-left: 0;
	margin-right: 0;
}


.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.pl-btn {
	background-color: #8E68B7;
	color: #fff;
	border: none;

	&:hover {
		background-color: #8E68B7;
		color: #fff;
		border: none;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.ql-editor strong {
	font-weight: bold;
}